<script setup>
const hmodal = ref("");
const untungRp = ref("");
const untungPersen = ref("");
const hasilna = ref("");
const pilihJenis = ref("");
const calculateRp = ref("0");
const calculatePersen = ref("");
const error1 = ref("");
const error2 = ref("");
const error3 = ref("");
const gaya = ref("");
const gaya2 = ref("");
const varPilihJenis = ref("");
const varPilihJenisRp = ref("");
const varPilihJenisPersen = ref("");
const varTotalPlatform = ref("");
const jenisna = ref([
  { nama: "GoFood", id: 1, persen: 20, rp: 1000 },
  { nama: "GrabFood", id: 2, persen: 30, rp: 0 },
  { nama: "ShopeeFood", id: 3, persen: 20, rp: 0 },
  { nama: "Offline - Manual", id: 4, persen: 0, rp: 0 },
]);
// supabase data
// const supabase = useSupabaseClient()
// const countries = ref([])

// async function getCountries() {
//   const { data } = await supabase.from('countries').select()
//   countries.value = data
// }

// onMounted(() => {
//   getCountries()
// })

const changeGaya = async () => {
  gaya.value = false;
  gaya2.value = false;
  if (untungRp.value) {
    gaya.value = false;
    gaya2.value = true;
  }
  if (untungPersen.value) {
    gaya.value = true;
    gaya2.value = false;
  }
};
const ketKeuntungan = async (event) => {
  var tmpJenisUntung = jenisna.value.find(
    (jenis) => jenis.id === parseInt(event.target.value) + 1
  );
  varPilihJenisPersen.value = tmpJenisUntung.persen;
  varPilihJenisRp.value = tmpJenisUntung.rp;
};
const deleteAll = async () => {
  hmodal.value = "";
  untungPersen.value = "";
  untungRp.value = "";
  pilihJenis.value = "";
};
const deleteAll2 = async () => {
  hmodal.value = "";
  untungPersen.value = "";
  untungRp.value = "";
  pilihJenis.value = "";
  hasilna.value = "";
  calculateRp.value = "";
  calculatePersen.value = "";
  varPilihJenis.value = "";
  varPilihJenisPersen.value = "";
  varPilihJenisRp.value = "";
  varTotalPlatform.value = "";

  const element = document.getElementById("hitungElement");
  element.scrollIntoView({
    behavior: "smooth",
    block: "start",
    inline: "nearest",
  });
};
const changeKeuntungan = async () => {
  if (untungRp.value != "") {
    calculateRp.value = untungRp.value;
    calculatePersen.value = Intl.NumberFormat().format(
      (parseFloat(untungRp.value) /
        (parseFloat(hmodal.value) + parseFloat(untungRp.value))) *
        100
    );
    calculatePersen.value =
      (
        (parseFloat(untungRp.value) /
          (parseFloat(hmodal.value) + parseFloat(untungRp.value))) *
        100
      ).toLocaleString() + " %";
  } else if (untungPersen.value != "") {
    calculatePersen.value = untungPersen.value;
    calculateRp.value = Number(
      (
        (parseFloat(hmodal.value) * parseFloat(untungPersen.value)) /
        (100 - untungPersen.value)
      ).toFixed()
    ).toLocaleString();
  }
};

const hitung = async () => {
  changeKeuntungan();
  var tmpJenis = jenisna.value.find((d) => d.id == pilihJenis.value + 1);
  varPilihJenis.value = tmpJenis.nama;
  var hargafinal = 0;
  if (untungPersen.value != "") {
    hargafinal = (hmodal.value * 100) / (100 - untungPersen.value);
  } else {
    hargafinal = parseInt(hmodal.value);
  }
  if (untungRp.value != "") {
    hargafinal = hargafinal + parseInt(untungRp.value);
  }
  var hargaSebelumPlatform = hargafinal;
  hargafinal = (hargafinal * 100) / (100 - tmpJenis.persen);
  hargafinal = hargafinal + parseInt(tmpJenis.rp);
  hasilna.value = Number(hargafinal.toFixed()).toLocaleString();
  varTotalPlatform.value = Number(
    (hargafinal - hargaSebelumPlatform).toFixed()
  ).toLocaleString();
};

function checkForm() {
  console.log("checkform");
  error1.value = null;
  error2.value = null;
  error3.value = null;
  if (!hmodal.value) {
    error1.value = "Harga Modal belum diisi";
  }
  if (!(untungPersen.value || untungRp.value)) {
    error2.value = "Keuntungan yang dikehendaki belum diisi";
  }
  if (pilihJenis.value === "") {
    error3.value = "Jenis Platform belum diisi";
  }
  // e.preventDefault();
  if (!(error1.value != null || error2.value != null || error3.value != null)) {
    // testhitung(1)
    hitung();
    const element = document.getElementById("panelHasil");
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  } else {
    console.log("loba error");
  }
}

const supabase = useSupabaseClient();
const showDisclaimer = ref(false);
const { data: blogs } = await supabase
  .from("blogs")
  .select("id, created_at, judul, short_desc, image")
  .lte("show_date", new Date().toISOString())
  .order("created_at", { ascending: false })
  .limit(4);

// Add Schema.org structured data
useHead({
  script: [
    {
      type: "application/ld+json",
      innerHTML: JSON.stringify({
        "@context": "https://schema.org",
        "@type": "Offer",
        itemOffered: {
          "@type": "Service",
          name: "Kalkulator Harga Bisnis Kuliner",
          description:
            "Kalkulator online gratis untuk menentukan harga jual optimal untuk platform food delivery.",
        },
        price: "0",
        priceCurrency: "IDR",
        availability: "https://schema.org/InStock",
        url: "https://www.hitung-harga-jual.com/hitungProduct",
        seller: {
          "@type": "Organization",
          name: "Hitung Harga Jual",
          url: "https://www.hitung-harga-jual.com",
        },
        inLanguage: "id-ID",
      }),
    },
    {
      type: "application/ld+json",
      innerHTML: JSON.stringify({
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            name: "Beranda",
            item: "https://www.hitung-harga-jual.com",
          },
        ],
      }),
    },
    {
      async: true,
      src: "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7524918493048672",
      crossorigin: "anonymous"
    }
  ],
});
</script>
<template>
  <div class="overflow-hidden">
    <!--
    supabase data
    <ul>
    <li v-for="country in countries" :key="country.id">{{ country.name }}</li>
  </ul>
-->
    <!-- <h1 class="text-3xl md:text-5xl lg:text-7xl text-slate-700 font-extrabold text-center mx-4 mb-4">
      Berapa <span class="text-greenbrand">harga</span>
      jual Online Delivery?
    </h1> -->
    <div class="bg-slate-100">
      <section
        class="relative min-h-[75vh] overflow-hidden px-8 flex flex-col mx-auto md:max-w-4xl"
      >
        <div>
          <div class="pt-4 px-4">
            <h1
              class="text-3xl md:text-5xl lg:text-7xl text-slate-700 font-extrabold text-center mx-4 mb-4"
            >
              Berapa <span class="text-greenbrand">harga</span>
              jual Online Delivery?
            </h1>
            <div class="mb-12">
              <h2
                class="text-center text-lg md:text-xl lg:text-2xl text-slate-400 font-semibold mb-4"
              >
                Bingung menghitung harga jual makanan online?
              </h2>
              <p
                class="text-sm md:text-lg lg:text-xl text-center text-slate-white md:max-w-3xl mx-auto"
              >
                Menghitung harga jual di platform online delivery seringkali
                membingungkan dengan perbedaan sistem komisi dari tiap platform
                ( GoFood, GrabFood, ShopeeFood) Gunakan kalkulator penghitung
                harga jual dibawah secara gratis.
              </p>
              <div class="relative z-10 text-center mt-12">
                <a href="#hitungElement" class="btn-primary">
                  Hitung Harga Jual
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          class="absolute m-auto left-0 right-0 bottom-0 w-full flex justify-between px-4"
        >
          <img class="h-48 md:h-64" src="../assets/images/48.png" alt="" />
          <img class="h-48 md:h-64" src="../assets/images/48-2.png" alt="" />
        </div>
      </section>
      <section class="md:max-w-4xl mx-auto px-4 py-8" id="hitungElement">
        <div class="mb-12 md:flex gap-4 justify-between">
          <!-- panel hitung -->
          <form
            class="bg-white rounded-3xl border-2 border-slate-100 drop-shadow-xl px-6 py-10 mb-8 md:mb-0 basis-3/4"
          >
            <div class="mb-4">
              <label
                class="block font-bold text-xl text-slate-500 mb-2"
                for="modal"
              >
                Harga Modal
              </label>
              <input
                class="w-full shadow-sm border-1 border-slate-300 appearance-none rounded bg-slate-100 py-4 px-3 text-gray-700 font-semibold leading-tight focus:outline-none focus:border-greenbrand focus:shadow-outline focus:ring-1 focus:ring-greenbrand"
                type="text"
                id="currency-field"
                pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$"
                data-type="currency"
                placeholder="Harga Modal"
                v-model="hmodal"
              />
              <div class="text-red-orange-ish text-sm font-semibold py-2 pl-2">
                {{ error1 }}
              </div>
            </div>
            <div class="w-full border-t border-gray-300 py-3"></div>
            <div class="mb-4">
              <label
                class="block font-bold text-xl text-slate-500 mb-2"
                for="profit"
              >
                Keuntungan
              </label>
              <!-- comment -->
              <input
                class="w-full shadow-sm border-1 border-slate-300 appearance-none rounded bg-slate-100 py-4 px-3 text-gray-700 font-semibold leading-tight focus:outline-none focus:border-greenbrand focus:shadow-outline focus:ring-1 focus:ring-greenbrand"
                id="profit-idr"
                type="text"
                placeholder="IDR"
                v-model="untungRp"
                :disabled="untungPersen != ''"
                :class="gaya ? 'class1' : 'class2'"
                v-on:input="changeGaya"
              />
              <p class="my-4 text-center text-slate-500 font-semibold text-lg">
                Atau
              </p>
              <!-- comment -->

              <input
                class="w-full shadow-sm border-1 border-slate-300 appearance-none rounded bg-slate-100 py-4 px-3 text-gray-700 font-semibold leading-tight focus:outline-none focus:border-greenbrand focus:shadow-outline focus:ring-1 focus:ring-greenbrand"
                id="profit-persen"
                type="text"
                placeholder="%"
                v-model="untungPersen"
                :disabled="untungRp != ''"
                :class="gaya2 ? 'class1' : 'class2'"
                v-on:input="changeGaya"
              />

              <p class="text-sm text-slate-500 text-center mt-4">
                Masukan keuntungan yang dikehendaki dalam Rupiah (IDR) atau
                persentase. <br />Persentase keuntungan dihitung dari harga jual
                - maksimal 99,99%
              </p>
              <div class="text-red-orange-ish text-sm font-semibold py-2 pl-2">
                {{ error2 }}
              </div>
            </div>
            <div class="w-full border-t border-gray-300 py-3"></div>
            <div class="mb-6">
              <label
                class="block font-bold text-xl text-slate-500 mb-2"
                for="
                    tipe_platform"
              >
                Pilih Platform Penjualan
              </label>
              <!-- comment -->
              <select
                v-model="pilihJenis"
                class="w-full shadow-sm border-1 border-slate-300 appearance-none rounded bg-slate-100 py-4 px-3 text-gray-700 font-semibold leading-tight focus:outline-none focus:border-greenbrand focus:shadow-outline focus:ring-1 focus:ring-greenbrand"
                @change="ketKeuntungan"
              >
                <option v-for="(jenis, id) in jenisna" :key="id" :value="id">
                  {{ jenis.nama }}
                </option>
              </select>
              <div class="text-red-orange-ish text-sm font-semibold py-2 pl-2">
                {{ error3 }}
              </div>
            </div>
            <!-- comment -->
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
            <div>
              <!-- comment -->
              <button
                id="food-hitung-btn"
                class="btn-primary"
                type="button"
                @click="checkForm"
              >
                Hitung Harga Jual
              </button>
              <button
                class="w-full md:max-w-lg text-sm border border-red-400 hover:bg-red-600 text-red-400 hover:text-white font-semibold py-3 px-5 rounded-full focus:outline-none focus:shadow-outline"
                type="button"
                @click="deleteAll"
              >
                Clear
              </button>
            </div>
          </form>
          <!-- panel hasil -->
          <div
            class="bg-greenbrand rounded-3xl drop-shadow-xl px-3 py-10 mb-8 md:mb-0 basis-1/2"
            id="panelHasil"
          >
            <div class="mb-8 h-32">
              <p class="mb-4 font-bold text-3xl text-white px-4">
                Rekomendasi <br />Harga Jual
              </p>
              <div class="text-3xl font-bold text-right text-white">
                {{ hasilna }}
              </div>
            </div>
            <div class="w-full border-t border-green-500"></div>
            <!-- Platform -->
            <div class="my-5 bg-green-700 rounded-lg p-4">
              <h3 class="mb-6 font-bold text-xl text-white">Potongan</h3>
              <div class="flex justify-between">
                <p class="font-semibold text-md text-white mb-4">Platform</p>
                <div class="text-md text-white font-semibold">
                  {{ varPilihJenis }}
                </div>
              </div>
              <div
                class="flex justify-between text-white mb-4 font-semibold text-md"
              >
                <div>
                  <p class="text-white">Komisi</p>
                </div>
                <div class="text-right text-white">
                  {{ varPilihJenisPersen }} %
                </div>
              </div>
              <div class="flex justify-between mb-4 font-semibold text-md">
                <div>
                  <p class="text-white">Biaya lainnya</p>
                </div>
                <div class="text-right text-white">
                  {{ varPilihJenisRp }}
                </div>
              </div>
              <div
                class="flex justify-between text-white font-semibold text-md"
              >
                <div>
                  <p class="text-md text-white">Total potongan</p>
                </div>
                <div class="text-right">
                  {{ varTotalPlatform }}
                </div>
              </div>
            </div>
            <div class="w-full border-t border-green-500"></div>
            <!-- Keuntungan -->
            <div class="my-5 px-4">
              <h3 class="mb-6 font-bold text-xl text-white">Keuntungan</h3>
              <div class="flex justify-between mb-4">
                <p class="font-semibold text-md text-white">Rupiah</p>
                <div class="text-md text-white font-semibold text-right">
                  {{ calculateRp }}
                </div>
              </div>
              <div class="flex justify-between mb-12">
                <p class="font-semibold text-md text-white">Persentase</p>
                <div class="text-md text-white font-semibold text-right">
                  {{ calculatePersen }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="md:max-4xl mb-12">
        <form class="text-center">
          <button
            class="w-72 md:max-w-lg bg-greenbrand border-2 hover:bg-green-700 text-white font-bold py-4 px-5 drop-shadow-xl rounded-full focus:outline-none focus:shadow-outline"
            type="button"
            @click="deleteAll2"
          >
            Hitung Harga lainnya
          </button>
        </form>
      </section>

      <section id="blogWrapper" class="px-4 md:px-10 py-10 mt-20 bg-slate-200">
        <h2 class="text-2xl font-semibold text-slate-500">Berita dan Tips</h2>
        <div class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 gap-4 my-8">
          <div v-for="blog in blogs.slice(0, 4)" :key="blog.id">
            <NuxtLink :to="`/blogs/${blog.id}`">
              <BlogCard :blog="blog" />
            </NuxtLink>
          </div>
        </div>
        <div class="flex justify-center mt-10">
          <button class="px-6 py-3 border border-slate-400 rounded-lg">
            <nuxt-link class="text-sm text-slate-500 font-medium" to="/blogs">
              Lihat Semua
            </nuxt-link>
          </button>
        </div>
        <div id="ads" class="banner hidden">
            <div class="hidden sm:flex mt-10 w-full justify-center">
            <a href="https://cafe-directories.pages.dev/" target="_blank" class="w-full">
              <img
              class="rounded-lg overflow-hidden w-full"
              src="/assets/images/leaderboard-01.png"
              alt="ngopi_di_mana-banner"
              />
            </a>
            </div>
            <div class="flex sm:hidden mt-10 w-full justify-center">
            <a href="https://cafe-directories.pages.dev/" target="_blank" class="w-full">
              <img
              class="rounded-lg overflow-hidden w-full"
              src="/assets/images/medium-rectangle-01.png"
              alt="ngopi_di_mana-banner"
              />
            </a>
            </div>
        </div>
      </section>

      <section id="disclaimer" class="px-4 py-4">
        <div
          class="mx-auto px-4 py-2 border rounded-lg border-slate-400 md:max-w-5xl"
        >
          <h4
            class="text-slate-400 font-semibold text-sm text-center underline cursor-pointer"
            @click="showDisclaimer = !showDisclaimer"
          >
            Disclaimer :
          </h4>
          <transition name="fade">
            <div v-if="showDisclaimer" class="mt-2">
              <ul class="text-sm list-none space-y-1">
                <li>
                  Kami akan selalu mencoba meng-update perhitungan potongan dari
                  platform terkini -
                  <span class="text-greenbrand font-semibold text-sm">
                    *Last update : 12 Mar 2025
                  </span>
                </li>
                <li>
                  Hitung Harga Jual Online - adalah platform gratis yang dibuat
                  untuk membantu para penjual online delivery, Hasil dari
                  perhitungan adalah dalam bentuk
                  <span class="font-bold">"rekomendasi"</span> dan tidak
                  menjamin terhindar dari kesalahan.
                </li>
                <li>
                  Hitung Harga Jual Online - adalah independen dan TIDAK
                  terafiliasi / bekerja sama dengan platform online apapun.
                </li>
              </ul>
            </div>
          </transition>
        </div>
      </section>
    </div>
  </div>
</template>

<style></style>
